import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type AutogenStatus =
  | 'CREATED'
  | 'PENDING'
  | 'PROCESSING'
  | 'COMPLETE'
  | 'FAILED';

const runningStates: AutogenStatus[] = ['PENDING', 'PROCESSING'];
const stoppedStates: AutogenStatus[] = ['COMPLETE', 'FAILED'];

export function isRunningStatus(status?: AutogenStatus): boolean {
  return status ? runningStates.includes(status) : false;
}

interface AutoGenProps {
  client: string;
  url: string;
}

interface UseAutoGenProps {
  client: string;
  url: string;
  refetchInterval?: number;
}

interface AutogenJob {
  status: AutogenStatus;
  pipeline: string;
}

async function fetchJob({ client, url }: AutoGenProps): Promise<AutogenJob> {
  const response = await fetch(`${url}/autoGenerator/public/jobs`, {
    headers: {
      Accept: 'application/json',
      'x-ebiai-account-id': client
    }
  });

  if (!response.ok) {
    throw new Error('Could not get autogen job');
  }

  return (await response.json()) as AutogenJob;
}

const useAutoGen = ({
  client,
  url,
  refetchInterval = 6000
}: UseAutoGenProps): UseQueryResult<AutogenJob> => {
  return useQuery({
    queryKey: ['autogen'],
    queryFn: () => fetchJob({ client, url }),
    refetchIntervalInBackground: true,
    refetchInterval: data => {
      if ((data && stoppedStates.includes(data.status)) || data === null) {
        return false;
      } else {
        return refetchInterval;
      }
    }
  });
};

export default useAutoGen;

import React from 'react';
import styled from 'styled-components';

import { colors, widths } from '../../constants';
import Sidebar from './Sidebar';
import Header from './Header';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  tagline?: string;
  link?: {
    name: string;
    path: string;
  };
  sideBarPosition: 'left' | 'right';
  children: React.ReactNode;
  className?: string;
}

export function SidebarLayout(props: Props) {
  const sidebarProps = {
    description: props.tagline,
    link: props.link
  };

  return (
    <Container className={props.className}>
      <Header />
      {props.sideBarPosition === 'left' && <Sidebar {...sidebarProps} />}
      <ContentArea>
        {props.children}
        {props.link && (
          <Footer className="footer">
            <Tagline>{props.tagline}</Tagline>
            <Link to={props.link.path}>{props.link.name}</Link>
          </Footer>
        )}
      </ContentArea>
      {props.sideBarPosition === 'right' && <Sidebar {...sidebarProps} />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    flex-direction: column;
  }
`;

const ContentArea = styled.div`
  padding: 50px;
  flex: 1;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    padding: 20px;
  }
`;

const Footer = styled.p`
  margin-top: 40px;
  margin-bottom: 5px;

  display: none;
  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    display: flex;
    margin-top: 30px;
  }
`;

const Tagline = styled.span`
  font-size: 16px;
  color: ${colors.DARK_BLUE};
`;

const Link = styled(RouterLink)`
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: ${colors.RED};

  :hover {
    text-decoration: underline;
  }
`;

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Layout from './Layout';
import Toaster from './Toaster';

import { OAuth, Code } from './pages/oauth';
import {
  SetupAssistant,
  SignUp,
  WaitingScreen,
  SetupIndustrySiteVisitors
} from './pages/sign-up';
import {
  ForgottenPassword,
  EnterRecoveryCode
} from './pages/forgotten-password';
import { Register } from './pages/register';

import { AcceptInvitation, RejectInvitation } from './pages/invitation';

import loginFactory from './api/login';

import './assets/fonts/fonts.css';
import './index.css';
import { Title } from './utils';
import { SetupPassword } from './pages/setup-password';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const env = {
  apiUrl: process.env.LOBSTER_API_URL || '',
  postRegistrationUrl: process.env.POST_REGISTRATION_URL || '',
  recaptchaKey: process.env.RECAPTCHA_KEY || '',
  oAuthClientDefinitions: process.env.OAUTH_CLIENT_DEFINITIONS || '',
  autogenUrl: process.env.AUTO_GEN_URL || '',
  reefUrl: process.env.REEF_URL || ''
};

const login = loginFactory({
  apiUrl: env.apiUrl
});

const autogen = {
  url: env.autogenUrl
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to={'/oauth'} />
      },
      {
        path: 'oauth',
        children: [
          {
            path: '',
            element: (
              <Title title="Login · EBI.AI">
                <OAuth
                  login={login}
                  clientDefinitions={JSON.parse(env.oAuthClientDefinitions)}
                  loginDefaultedRedirectUri={env.postRegistrationUrl}
                />
              </Title>
            )
          },
          {
            path: 'code',
            element: (
              <Title title="Enter Auth Code · EBI.AI">
                <Code login={login} />
              </Title>
            )
          }
        ]
      },
      {
        path: 'sign-up',
        children: [
          {
            path: '',
            element: (
              <Title title="Ready to meet your AI assistant? · EBI.AI">
                <SignUp loginDefaultedRedirectUri={env.postRegistrationUrl} />
              </Title>
            )
          },
          {
            path: 'setup-assistant',
            element: (
              <Title title="Ready to meet your AI assistant? · EBI.AI">
                <SetupAssistant />
              </Title>
            )
          },
          {
            path: 'setup-industry-visitors',
            element: (
              <Title title="Ready to meet your AI assistant? · EBI.AI">
                <SetupIndustrySiteVisitors login={login} />
              </Title>
            )
          }
        ]
      },
      {
        path: 'setup-password',
        element: (
          <Title title="Setup password · EBI.AI">
            <SetupPassword
              login={login}
              postRegistrationUrl={env.postRegistrationUrl}
            />
          </Title>
        )
      },
      {
        path: 'register',
        element: (
          <Title title="Register · EBI.AI">
            <Register
              login={login}
              postRegistrationUrl={env.postRegistrationUrl}
            />
          </Title>
        )
      },
      {
        path: 'forgotten-password',
        children: [
          {
            path: '',
            element: (
              <Title title="Forgotten Password · EBI.AI">
                <ForgottenPassword login={login} />
              </Title>
            )
          },
          {
            path: 'enter-recovery-code',
            element: (
              <Title title="Enter Recovery Code · EBI.AI">
                <EnterRecoveryCode login={login} />
              </Title>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'sign-up/generating-ai',
    element: (
      <Title title="Ready to meet your AI assistant? · EBI.AI">
        <WaitingScreen autogen={autogen} reefUrl={env.reefUrl} />
      </Title>
    )
  },
  {
    path: 'accept-invitation',
    element: (
      <AcceptInvitation
        login={login}
        postRegistrationUrl={env.postRegistrationUrl}
      />
    )
  },
  {
    path: 'reject-invitation',
    element: (
      <RejectInvitation
        login={login}
        postRegistrationUrl={env.postRegistrationUrl}
      />
    )
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={env.recaptchaKey}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
      <Toaster />
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

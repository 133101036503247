import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/logo.svg';
import headerBackground from '../../assets/headerBackground.svg';
import { widths } from '../../constants';

export default function Header() {
  return (
    <Container>
      <Logo src={logo} />
    </Container>
  );
}

const Container = styled.div`
  background: url(${headerBackground}) no-repeat, #d4e8e5;
  width: 335px;
  height: 184px;

  justify-content: center;
  
  padding-top: 30px;

  display: none;
  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    display: flex;
  }
`;

const Logo = styled.img`
  align-self: flex-start;
`;

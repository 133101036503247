import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Login } from '../../api/login';

import createAssistantBackground from '../../assets/createAssistantBackground.svg';
import smallCreateAssistantBackground from '../../assets/smallCreateAssistantBackground.svg';

import retailIcon from '../../assets/icons/industry/retail.svg';
import propertyIcon from '../../assets/icons/industry/property.svg';
import insuranceIcon from '../../assets/icons/industry/insurance.svg';
import leisureIcon from '../../assets/icons/industry/leisure.svg';
import ecommerceIcon from '../../assets/icons/industry/ecommerce.svg';
import localCouncilIcon from '../../assets/icons/industry/localCouncil.svg';
import itIcon from '../../assets/icons/industry/it.svg';
import legalIcon from '../../assets/icons/industry/legal.svg';
import constructionIcon from '../../assets/icons/industry/construction.svg';
import beautyIcon from '../../assets/icons/industry/beauty.svg';
import otherIcon from '../../assets/icons/industry/other.svg';

import { Button, GraphicRadioButton, RadioButton } from '../../shared';

import { runEventHandler } from '../../utils';
import { colors, widths } from '../../constants';
import { useWindowSize } from '../../utils/useWindowSize';

interface SetupIndustrySiteVisitorsFormData {
  industry: string;
  visitors: string;
}

interface Props {
  login: Login;
}

const signUpClientSessionStorage = 'signUpClientKey';

export function SetupIndustrySiteVisitors(props: Props) {
  const navigate = useNavigate();

  const { width: screenWidth } = useWindowSize();
  const isMobile = screenWidth < widths.MAX_SMALL_SCREEN;

  const { state } = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { register, handleSubmit } =
    useForm<SetupIndustrySiteVisitorsFormData>();

  const [loading, setLoading] = useState<boolean>(false);
  const [actionSelected, setActionSeleted] = useState<'create' | 'skip' | null>(
    null
  );

  const industryData = [
    { icon: retailIcon, label: 'Retail', value: 'Retail' },
    { icon: propertyIcon, label: 'Property', value: 'Property' },
    { icon: insuranceIcon, label: 'Insurance', value: 'Insurance' },
    { icon: leisureIcon, label: 'Leisure', value: 'Leisure' },
    { icon: ecommerceIcon, label: 'E-commerce', value: 'E-commerce' },
    { icon: localCouncilIcon, label: 'Local Council', value: 'Local Council' },
    { icon: itIcon, label: 'IT', value: 'IT' },
    { icon: legalIcon, label: 'Legal', value: 'Legal' },
    { icon: constructionIcon, label: 'Construction', value: 'Construction' },
    { icon: beautyIcon, label: 'Beauty', value: 'Beauty' },
    { icon: otherIcon, label: 'Other', value: 'Other' }
  ];

  const websiteVisitors = [
    { children: 'Not yet launched', value: 'Not yet launched' },
    { children: '1,001 - 5,000 visitors', value: '1,001 - 5,000 visitors' },
    { children: 'Less than 500 visitors', value: 'Less than 500 visitors' },
    { children: '5,001 - 10,000 visitors', value: '5,001 - 10,000 visitor' },
    { children: '500 - 1,000 visitors', value: '500 - 1,000 visitors' },
    {
      children: '10,001 - 25,000+ visitors',
      value: '10,001 - 25,000+ visitors'
    }
  ];

  const onSubmit = async (data?: SetupIndustrySiteVisitorsFormData) => {
    try {
      setLoading(true);
      const token = (await (executeRecaptcha && executeRecaptcha())) || '';
      const storedClient = getStoredClient();
      const industry = data?.industry;
      const visitors = data?.visitors;

      const alreadySaved = isClientSaved(storedClient, {
        ...state,
        industry,
        visitors
      });

      let registrationLink, clientId;

      if (!(storedClient.registrationLink && storedClient.clientId) || !alreadySaved) {
        const { firstName, lastName, email, business, url } = state;

        const createdClient = await props.login.createClient(
          firstName,
          lastName,
          email,
          business,
          url,
          industry,
          visitors,
          token
        );

        registrationLink = createdClient.registrationLink;
        clientId = createdClient.clientId;

        sessionStorage.setItem(
          getClientSessionKey(),
          JSON.stringify({
            registrationLink,
            clientId,
            firstName,
            lastName,
            email,
            business,
            url,
            industry,
            visitors
          })
        );

        window.dataLayer.push({ event: 'sign-up-complete', user_id: clientId });
      } else {
        registrationLink = storedClient.registrationLink;
        clientId = storedClient.clientId;
      }

      const registrationCode =
        new URLSearchParams(registrationLink).get('registration_code') || '';

      await props.login.setupPassword(
        state.email,
        registrationCode,
        state.password,
        state.confirmPassword
      );

      if (!isMobile) {
        await props.login.sendMFACode(state.email, state.password);

        navigate('/oauth/code', { state });
      } else {
        // Navigate to waiting screen if using mobile
        navigate(`/sign-up/generating-ai?client=${clientId}`);
      }
    } catch {
      setLoading(false);
      setActionSeleted(null);
    }
  };

  if (!state) {
    return <Navigate to=".." replace={true} />;
  }

  const isClientSaved = (
    savedClient: Record<string, string>,
    newClient: Record<string, string>
  ) => {
    return (
      savedClient &&
      savedClient.firstName === newClient.firstName &&
      savedClient.lastName === newClient.lastName &&
      savedClient.email === newClient.email &&
      savedClient.url === newClient.url
    );
  };

  const getClientSessionKey = () =>
    `${signUpClientSessionStorage}-${state.state}`;

  const getStoredClient = () => {
    const savedClient = sessionStorage.getItem(getClientSessionKey());

    if (savedClient) {
      return JSON.parse(savedClient);
    }
  };

  return (
    <Form
      onSubmit={runEventHandler(handleSubmit(onSubmit))}
      isLoading={loading}
    >
      <Container>
        <H1>Please select your industry</H1>
        <P>
          AI is everywhere. It isn’t just for tech companies or industry
          frontrunners, anybody with a website can have an AI assistant. Please
          select your industry to continue
        </P>
        <Industries>
          {industryData.map((item, index) => (
            <GraphicRadioButton
              key={index}
              icon={item.icon}
              label={item.label}
              value={item.value}
              {...register('industry')}
            />
          ))}
        </Industries>
      </Container>
      <Divider />
      <Container>
        <H1>Website visitors</H1>
        <P>How many monthly visitors does your website receive on average?</P>
        <Visitors>
          {websiteVisitors.map((visitor, index) => (
            <RadioButton
              key={index}
              label={visitor.children}
              value={visitor.value}
              {...register('visitors')}
            />
          ))}
        </Visitors>
      </Container>
      {/* Set disabled to prevent clicking on other actions when an action is already selected. */}
      <ButtonRow>
        <Button
          type="submit"
          isLoading={loading && actionSelected === 'create'}
          onClick={() => setActionSeleted('create')}
          disabled={!!actionSelected && loading}
        >
          Create
        </Button>
        <Button
          type="button"
          kind="text"
          onClick={runEventHandler(() => {
            setActionSeleted('skip');
            onSubmit();
          })}
          isLoading={loading && actionSelected === 'skip'}
          disabled={!!actionSelected && loading}
        >
          Skip
        </Button>
      </ButtonRow>
    </Form>
  );
}

interface FormProps {
  isLoading: boolean;
}

const Form = styled.form<FormProps>`
  height: 720px;
  background: url(${createAssistantBackground}) no-repeat;
  background-position: bottom;
  padding: 20px 50px 93px;

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: progress;
    `}

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    padding: 20px;
    padding-bottom: 35px;
    background: url(${smallCreateAssistantBackground}) no-repeat;
    background-position: center bottom -1px;
    height: unset;
  }
`;

const Container = styled.div`
  margin: 10px 0;
`;

const H1 = styled.h1`
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
  color: ${colors.DARK_BLUE};
  margin: 0;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 5px;
  }
`;

const P = styled.div`
  display: flex;
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  color: ${colors.DARK_BLUE};

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 12px;
    line-height: 14px;
    width: 100%;
  }
`;

const ButtonRow = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  gap: 21px;
  margin-top: 24px;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    margin-top: 5px;
    margin-bottom: 80px;
  }
`;

const Industries = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
`;

const Divider = styled.div`
  display: inline-block;
  background: ${colors.DARK_BLUE};
  width: 100%;
  height: 2px;
  opacity: 0.3;
  margin: 0;
`;

const Visitors = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px 40px;
`;

import React, { useState, useId } from 'react';
import styled, { css } from 'styled-components';
import { colors, widths } from '../constants';

import hiddenEye from '../assets/hiddenEye.svg';
import visibleEye from '../assets/visibleEye.svg';

import { ErrorMessage } from './ErrorMessage';

interface Props extends React.ComponentPropsWithoutRef<'input'> {
  icon: string;
  errorMessage?: string;
}

function TextInput(
  { icon, errorMessage, ...props }: Props,
  ref: React.Ref<HTMLInputElement>
) {
  const [isRevealed, setRevealed] = useState(false);

  const type =
    props.type === 'password' ? (isRevealed ? 'text' : 'password') : props.type;

  const errorId = useId();

  return (
    <Container icon={icon}>
      <Input
        ref={ref}
        redHighlight={Boolean(errorMessage)}
        aria-label={props.placeholder}
        aria-describedby={errorId}
        {...props}
        type={type}
      />
      {props.type === 'password' && (
        <PasswordReveal
          visible={isRevealed}
          onClick={() => setRevealed(r => !r)}
        />
      )}
      <ErrorMessage id={errorId} className="error">
        {errorMessage || ''}
      </ErrorMessage>
    </Container>
  );
}

export default React.forwardRef(TextInput);

interface ContainerProps {
  icon: string;
}

const Container = styled.div<ContainerProps>`
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 18px;
    width: 15px;
    height: 15px;
    background-position: center;
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;

    @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
      top: 14px;
    }
  }
`;

interface PasswordRevealProps {
  visible: boolean;
}

const PasswordReveal = styled.div<PasswordRevealProps>`
  content: '';
  position: absolute;
  top: 15px;
  right: 18px;
  width: 18px;
  height: 16px;
  background-position: center;
  background-image: url(${({ visible }) => (visible ? visibleEye : hiddenEye)});
  background-repeat: no-repeat;
`;

interface InputProps {
  redHighlight: boolean;
}

const Input = styled.input<InputProps>`
  width: 100%;
  border-radius: 27px;
  padding: 12px;
  padding-left: 38px;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.DARK_BLUE};
  border: 1px solid ${colors.DARK_BLUE};

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 12px;
    line-height: 16px;
  }

  ${({ redHighlight }) =>
    redHighlight &&
    css`
      border-color: ${colors.RED};
    `}

  ::placeholder {
    font-size: 14px;
    line-height: 19px;
    font-style: italic;
    color: ${colors.GREY_TEXT};

    @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  :focus-visible {
    border: 2px solid ${colors.DARK_BLUE};
    outline: none;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 37px;
  }
`;

import { EventHandler, SyntheticEvent } from 'react';
import { toast } from 'react-hot-toast';

export function runEventHandler<T extends SyntheticEvent<unknown>>(
  fn: EventHandler<T>
): EventHandler<T> {
  return async event => {
    try {
      await fn(event);
    } catch (err) {
      if (err instanceof UserFriendlyError) {
        toast.error(err.message);
      } else {
        console.error(err); // eslint-disable-line no-console
        toast.error("Something went wrong, but don't worry. Please give it another go.");
      }
    }
  };
}

export class UserFriendlyError extends Error {
  constructor(m: string) {
      super(m);
      Object.setPrototypeOf(this, UserFriendlyError.prototype);
  }
}

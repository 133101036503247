import React from 'react';
import styled from 'styled-components';
import { colors, widths } from '../constants';

import greenCheck from '../assets/greencheck.svg';

interface GraphicRadioButtonProps
  extends React.ComponentPropsWithoutRef<'input'> {
    label: string;
  icon: string;
}

function GraphicRadioButton(
  { icon, label, ...inputProps }: GraphicRadioButtonProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <Label>
      <Input type="radio" ref={ref} {...inputProps} />
      <ImageWrapper>
        <Img src={icon}  />
        {label}
      </ImageWrapper>
    </Label>
  );
}

export default React.forwardRef(GraphicRadioButton);

const Label = styled.label`
  display: flex;
  align-items: center;
  text-align: center;
  width: calc(25% - 20px);
  margin-right: 20px;
  margin-top: 10px;

  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.DARK_BLUE};
  cursor: pointer;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 12px;
    line-height: 16px;
    width: calc(50% - 20px);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  border-color: ${colors.DARK_BLUE};
  border-style: solid;
  border-width  : 1px;
  border-radius: 10px;
  padding: 7px;
  margin-bottom: 5px;

  input:checked + & {
    border-color: ${colors.GREEN};
    border-width  : 2px;
    :after {
      content: '';
      position: absolute;
      top: -7px;
      right: -7px;
      width: 20px;
      height: 20px;
      background: url(${greenCheck});
      background-size: contain;
      border-radius: 50%;
    }
  }

  input:focus-visible + & {
    border-color: ${colors.DARK_BLUE};
  }

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    text-align: left;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
`;

const Img = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 10px;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    width: 36px;
    height: 36px;
  }
`;
import React from 'react';
import { Toaster, ToastBar, toast } from 'react-hot-toast';
import styled from 'styled-components';
import { colors } from './constants';

import dismiss from './assets/dismiss.svg';

export default function Layout() {
  return (
    <Toaster
      toastOptions={{
        style: {
          boxShadow: '0px 5px 20px #26413f26',
          color: colors.DARK_BLUE,
          fontSize: 14,
          padding: '9px 18px',
          borderRadius: 10,
          maxWidth: 550
        }
      }}
    >
      {t => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <DismissButton onClick={() => toast.dismiss(t.id)} />
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

const DismissButton = styled.button`
  background: center url(${dismiss}) no-repeat;
  width: 14px;
  height: 14px;
  border: none;
  transition: filter 150ms;

  :hover {
    filter: drop-shadow(0px 1px 2px #00000065);
  }

  :active {
    filter: none;
  }
`;

import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/ai-logo-mobile.svg';
import backgroundImage from '../../assets/mobile-background.svg';
import switchToDesktopImage from '../../assets/switch-to-desktop.svg';
import clawsonGif from '../../assets/clawson-loader.gif';
import { Button } from '../../shared';
import { colors } from '../../constants';
import useAutoGen, { isRunningStatus } from '../../api/useAutoGen';
import { useSearchParams } from 'react-router-dom';

interface WaitingScreenProps {
  autogen: {
    url: string;
  };
  reefUrl: string;
}

export const WaitingScreen: React.FC<WaitingScreenProps> = ({
  autogen,
  reefUrl
}: WaitingScreenProps) => {
  const [searchParams] = useSearchParams();
  const client = searchParams.get('client')?.toString() || '';
  const { data } = useAutoGen({ url: autogen.url, client });

  const isRunningAutogen = isRunningStatus(data?.status);

  const goToLandingPage = () => {
    const link = `${reefUrl}/get-started?client=${client}&pipeline=${data?.pipeline}`;
    window.open(link, '_blank');
  };

  return (
    <>
      <HeaderWrapper>
        <img src={logo} alt="" />
      </HeaderWrapper>
      <Container>
        <Card>
          <Title>
            <TitleImage src={switchToDesktopImage} alt="" />
            AI Studio on mobile
          </Title>
          <Body>
            AI Studio, our platform for managing and building AI assistants, is
            currently optimised for desktop use only. <br />
            <br />
            If you gave a website URL on sign up, you can begin talking on your
            mobile in a couple of minutes once we have finished creating and
            training. <br /> <br />
            However, for customising responses and training your AI further log
            in from a desktop device.
          </Body>

          {isRunningAutogen || !data?.pipeline ? (
            <>
              <ClawsonImage src={clawsonGif} />
              <ClawsonSubtitle>Generating your AI</ClawsonSubtitle>
            </>
          ) : (
            <TryMyAiButton onClick={goToLandingPage}>Try my AI</TryMyAiButton>
          )}
        </Card>
      </Container>
    </>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 30px;
  box-shadow: 0px 1px 4px #0015291f;
  z-index: 2;
  height: 65px;
  background-color: ${colors.DARK_BLUE};
`;

const Title = styled.h4`
  color: ${colors.DARK_BLUE};
  font-size: 18px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
`;

const TitleImage = styled.img`
  margin-right: 10px;
`;

const ClawsonImage = styled.img`
  width: 159px;
  height: 122px;
  object-fit: cover;
  margin: 10px auto 16px;
`;

const ClawsonSubtitle = styled.p`
  color: ${colors.RED};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  background-color: white;
  padding-top: 35px;
  justify-content: center;
  height: 100%;
`;

const Card = styled.div`
  background: white;
  box-shadow: 0px 5px 20px #06233d65;
  border-radius: 10px;
  max-width: 345px;
  padding: 20px;
  margin: 0px 15px;
  background: url(${backgroundImage}) no-repeat;
  background-position: center bottom -1px;
  display: flex;
  flex-direction: column;
  min-height: 562px;
  height: min-content;
`;

const Body = styled.div`
  color: ${colors.DARK_BLUE}
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 0px;
`;

const TryMyAiButton = styled(Button)`
  margin: 70px auto 0px;
  height: 45px;
  width: 120px;
  font-size: 14px;
  font-weight: bold;
`;

export default WaitingScreen;

import React from 'react';
import styled from 'styled-components';
import {
  Button,
  Heading,
  Link,
  SidebarLayout,
  Text,
  TextInput
} from '../../shared';

import recoveryCodeIcon from '../../assets/icons/recoveryCode.svg';
import passwordIcon from '../../assets/icons/password.svg';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { runEventHandler } from '../../utils';
import { InvalidOrExpiredCodeError, Login } from '../../api/login';
import { UserFriendlyError } from '../../utils/runEventHandler';

interface FormData {
  code: string;
  password: string;
  confirmPassword: string;
}

interface Props {
  login: Login;
}

export function EnterRecoveryCode(props: Props) {
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset
  } = useForm<FormData>();

  const isLoading = isSubmitSuccessful || isSubmitting;

  const onSubmit = async (data: FormData) => {
    try {
      await props.login.changePassword(
        state.email,
        data.code,
        data.password,
        data.confirmPassword
      );
      navigate('/oauth');
    } catch (err) {
      reset(data);
      if (err instanceof InvalidOrExpiredCodeError) {
        throw new UserFriendlyError(
          'The code you have entered is incorrect. Please request a new code.'
        );
      }
      throw err;
    }
  };

  if (!state?.email) {
    return <Navigate to=".." replace={true} />;
  }

  return (
    <SidebarLayout sideBarPosition="right">
      <Form
        noValidate
        onSubmit={runEventHandler(handleSubmit(onSubmit))}
        isLoading={isLoading}
      >
        <Heading title="Forgotten password" />
        <TextInput
          icon={recoveryCodeIcon}
          placeholder="Recovery code"
          type="text"
          errorMessage={errors?.code?.message}
          {...register('code', {
            required: {
              value: true,
              message: 'Please enter your recovery code'
            },
            pattern: {
              value: /^[0-9a-zA-Z]{8}$/,
              message: 'Please enter a valid recovery code'
            }
          })}
        />
        <TextInput
          icon={passwordIcon}
          errorMessage={errors?.password?.message}
          autoComplete="new-password"
          type="password"
          placeholder="New password"
          {...register('password', {
            required: {
              value: true,
              message: 'Please enter your new password'
            },
            minLength: {
              value: 12,
              message: 'Your password must be at least 12 characters long'
            },
            validate: {
              lowercase: (v: string) =>
                /[a-z]/.test(v) ||
                'Your password must contain a lowercase character',
              uppercase: (v: string) =>
                /[A-Z]/.test(v) ||
                'Your password must contain an uppercase character',
              numeric: (v: string) =>
                /[0-9]/.test(v) || 'Your password must contain a number',
              special: (v: string) =>
                /[^a-zA-Z0-9]/.test(v) ||
                'Your password must contain a special character'
            }
          })}
        />
        <TextInput
          icon={passwordIcon}
          errorMessage={errors?.confirmPassword?.message}
          autoComplete="new-password"
          type="password"
          placeholder="Re-enter new password"
          {...register('confirmPassword', {
            required: {
              value: true,
              message: 'Please confirm your new password'
            },
            validate: (value: string) =>
              value === watch('password') || "Your passwords don't match",
            deps: 'password'
          })}
        />
        <Link to=".." state={{ email: state?.email }}>
          Need a new code?
        </Link>
        <Text>
          An email containing a password recovery code, that you will need to
          reset your password, has been sent to the email address you use to
          access your account.
        </Text>
        <Text>
          Choose a new password that’s at least 12 characters long and includes
          one uppercase character, one lowercase character, one number and a
          special symbol.
        </Text>
        <Footer>
          <Link to=".." state={{ email: state?.email }}>
            <Button kind="outlined">Back</Button>
          </Link>
          <Button type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </Footer>
      </Form>
    </SidebarLayout>
  );
}

interface FormProps {
  isLoading: boolean;
}

const Form = styled.form<FormProps>`
  flex: 1;

  display: flex;
  flex-direction: column;

  ${({ isLoading }) => isLoading && 'cursor: progress;'}
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;

  button {
    margin-right: 15px;
  }
`;

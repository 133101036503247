import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Heading, TextInput, Button, Checkbox } from '../../shared';

import personIcon from '../../assets/icons/person.svg';
import emailIcon from '../../assets/icons/email.svg';
import passwordIcon from '../../assets/icons/password.svg';
import organisationIcon from '../../assets/icons/organisation.svg';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { SidebarLayout } from '../../shared/SidebarLayout/SidebarLayout';
import { SESSION_STORAGE_KEYS, colors, widths } from '../../constants';
export interface SignUpFields {
  firstName: string;
  lastName: string;
  email: string;
  business: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
}

interface Props {
  loginDefaultedRedirectUri: string;
}

export function SignUp(props: Props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm<SignUpFields>({
    defaultValues: {
      email: searchParams.get('user_email') ?? undefined
    }
  });

  useEffect(() => {
    const previousState = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.SIGNUP_SESSION
    );

    if (previousState) {
      reset(JSON.parse(previousState));
    }
  }, [reset]);

  const onSubmit = ({ password, confirmPassword, ...data }: SignUpFields) => {
    window.dataLayer.push({ event: 'sign-up-continue' });
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.SIGNUP_SESSION,
      JSON.stringify(data)
    );
    navigate('setup-assistant', {
      state: {
        ...data,
        state: searchParams.get('state'),
        client_id: searchParams.get('client_id'),
        redirect_uri: searchParams.get('redirect_uri'),
        password,
        confirmPassword
      }
    });
  };

  const reefState = searchParams.get('state');

  if (!reefState || reefState === '') {
    window.location.assign(
      `${props.loginDefaultedRedirectUri}?redirect_uri=${window.location.href}`
    );
  }

  return (
    <CustomSidebarLayout
      sideBarPosition="right"
      tagline="Already have an account?"
      link={{
        path: '/oauth',
        name: 'Login'
      }}
    >
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Heading
          title="Ready to meet your AI assistant?"
          subtitle="First 30 days free,  No credit card required"
        />
        <Row>
          <TextInput
            autoComplete="given-name"
            icon={personIcon}
            errorMessage={errors?.firstName?.message}
            placeholder="First name"
            {...register('firstName', {
              required: {
                value: true,
                message: 'Please enter your first name'
              }
            })}
          />
          <TextInput
            autoComplete="family-name"
            icon={personIcon}
            errorMessage={errors?.lastName?.message}
            placeholder="Last name"
            {...register('lastName', {
              required: {
                value: true,
                message: 'Please enter your last name'
              }
            })}
          />
        </Row>
        <TextInput
          icon={emailIcon}
          errorMessage={errors?.email?.message}
          autoComplete="email"
          type="email"
          placeholder="Email"
          {...register('email', {
            required: {
              value: true,
              message: 'Please enter your email'
            },
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email'
            },
            validate: {
              lowercase: (v: string) =>
                !/[A-Z]/.test(v) ||
                'Your email must not contain an uppercase character'
            }
          })}
        />
        <TextInput
          autoComplete="family-name"
          icon={organisationIcon}
          errorMessage={errors?.business?.message}
          placeholder="Organisation"
          {...register('business', {
            required: {
              value: true,
              message: 'Please enter your organisation name'
            }
          })}
        />
        <TextInput
          icon={passwordIcon}
          errorMessage={errors?.password?.message}
          autoComplete="new-password"
          type="password"
          placeholder="Password"
          {...register('password', {
            required: {
              value: true,
              message: 'Please enter your new password'
            },
            minLength: {
              value: 12,
              message: 'Password must be at least 12 characters long'
            },
            validate: {
              lowercase: (v: string) =>
                /[a-z]/.test(v) ||
                'Your password must contain a lowercase character',
              uppercase: (v: string) =>
                /[A-Z]/.test(v) ||
                'Your password must contain an uppercase character',
              numeric: (v: string) =>
                /[0-9]/.test(v) || 'Your password must contain a number',
              special: (v: string) =>
                /[^a-zA-Z0-9]/.test(v) ||
                'Your password must contain a special character'
            }
          })}
        />
        <TextInput
          icon={passwordIcon}
          errorMessage={errors?.confirmPassword?.message}
          autoComplete="new-password"
          type="password"
          placeholder="Confirm password"
          {...register('confirmPassword', {
            required: {
              value: true,
              message: 'Please confirm your new password'
            },
            validate: (value: string) =>
              value === watch('password') || "Your passwords don't match",
            deps: 'password'
          })}
        />
        <StyledCheckbox
          errorMessage={errors?.acceptTerms?.message}
          {...register('acceptTerms', {
            required: {
              value: true,
              message:
                'Please accept the terms & conditions and privacy policy to continue'
            }
          })}
        >
          I accept the&nbsp;
          <ExternalLink
            href="https://ebi.ai/customer-terms-and-conditions/"
            target="_blank"
          >
            Terms & Conditions
          </ExternalLink>
          &nbsp;and&nbsp;
          <ExternalLink href="https://ebi.ai/privacy-policy/" target="_blank">
            Privacy Policy
          </ExternalLink>
        </StyledCheckbox>
        <Footer>
          <Button data-test="continue" type="submit">
            Continue
          </Button>
        </Footer>
      </Form>
    </CustomSidebarLayout>
  );
}

const CustomSidebarLayout = styled(SidebarLayout)`
  .sidebar-container {
    padding-bottom: 135px;
  }

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    .footer {
      display: none;
    }
  }
`;

const Form = styled.form`
  flex: 1;

  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

const Row = styled.div`
  display: flex;
  gap: 24px;
`;

const StyledCheckbox = styled(Checkbox)`
  label {
    font-size: 16px;
    font-style: normal;
    @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
      font-size: 13px;
      line-height: 18px;
    }
  }

  input {
    accent-color: ${colors.GREEN};
  }
`;

const ExternalLink = styled.a`
  color: ${colors.GREEN};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 13px;
    line-height: 18px;
  }

  :hover {
    text-decoration: underline;
  }
`;

import styled from 'styled-components';
import { colors, widths } from '../constants';

import informationSvg from '../assets/information.svg';

export const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  color: ${colors.RED};
  margin-bottom: 9px;
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;

  :not(:empty):before {
    content: url(${informationSvg});
    margin-right: 5px;
    margin-top: 1px;
  }

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    margin-bottom: 5px;
  }
`;

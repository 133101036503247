import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

import logo from '../../assets/logo.svg';
import sidebarBackground from '../../assets/sidebarBackground.svg';
import { colors, widths } from '../../constants';
import { Link } from 'react-router-dom';

interface Props {
  description?: string;
  link?: {
    name: string;
    path: string;
  };
}

export default function Sidebar(props: Props) {
  return (
    <Container className="sidebar-container">
      <Logo src={logo} />
      {props.description && <TagLine>{props.description}</TagLine>}
      {props.link && (
        <Link to={props.link.path}>
          <Button kind="outlined">{props.link.name}</Button>
        </Link>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 282px;
  background: url(${sidebarBackground}) no-repeat, #d4e8e5;
  padding: 50px;
  display: grid;
  grid-row-gap: 25px;
  grid-template-rows: 1fr min-content min-content;
  justify-items: center;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    display: none;
  }
`;

const Logo = styled.img`
  justify-self: start;
  flex: 1;
  margin: 20px 0;
  width: 189px;
  height: 27px;
`;

const TagLine = styled.p`
  color: ${colors.WHITE};
  margin: 0;
`;

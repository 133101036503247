import React from 'react';
import { useForm } from 'react-hook-form';
import { Login } from '../../api/login';
import { Button } from '../../shared';
import { runEventHandler } from '../../utils';

interface Props {
  email: string;
  password: string;
  login: Login;
  onResend?: () => void;
}

export default function ResendButton(props: Props) {
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useForm();

  const submit = async () => {
    props.onResend?.();
    await props.login.sendMFACode(props.email, props.password);
  };

  return (
    <form onSubmit={runEventHandler(handleSubmit(submit))}>
      <Button kind="outlined" type="submit" isLoading={isSubmitting}>
        Resend code
      </Button>
    </form>
  );
}

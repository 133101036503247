import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors, widths } from '../constants';

interface Props {
  disabled?: boolean;
}

export default styled(Link)<Props>`
  margin: 2px 0;
  font-size: 18px;
  font-style: italic;
  text-decoration: none;
  color: ${colors.RED};

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 14px;
    line-height: 19px;
  }

  :hover {
    text-decoration: underline;
  }

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    color: #97A4B1;
    cursor: not-allowed;
    :hover {
      text-decoration: none;
    }
  `}
`;

interface Settings {
  apiUrl: string;
}

interface ResponseError {
  code: number;
  errorType?: string;
  message: string;
  errors: {
    location: string;
    msg: string;
    param: string;
    value: string;
  }[];
}

interface CreateClientResponse {
  registrationLink: string;
  clientId: string;
}

interface CodeResponse {
  code: string;
  state: string;
  client: string;
}

export enum Template {
  Property = 'property',
  Insurance = 'insurance',
  Leisure = 'leisure',
  LocalCouncil = 'community-ai',
  Retail = 'retail',
  Travel = 'travel'
}

export class IncorrectCredentialsError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, IncorrectCredentialsError.prototype);
  }
}

export class InvalidOrExpiredCodeError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, InvalidOrExpiredCodeError.prototype);
  }
}

export class DisabledAccountError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, DisabledAccountError.prototype);
  }
}

export interface Login {
  isValidClientAndRedirectUrl(): Promise<boolean>;
  sendMFACode(email: string, password: string): Promise<void>;
  getAuthorizationCode(
    email: string,
    password: string,
    code: string,
    client_id: string,
    redirect_uri: string,
  ): Promise<CodeResponse>;
  sendPasswordResetCode(email: string): Promise<void>;
  setupPassword(
    email: string,
    code: string,
    password: string,
    confirmationPassword: string
  ): Promise<void>;
  changePassword(
    email: string,
    code: string,
    password: string,
    confirmationPassword: string
  ): Promise<void>;
  createClient(
    firstName: string,
    lastName: string,
    email: string,
    business: string,
    url: string | void,
    industry: string | void,
    visitors: string | void,
    recaptchaToken: string
  ): Promise<CreateClientResponse>;
  register(
    firstName: string,
    lastName: string,
    email: string,
    code: string,
    password: string,
    confirmPassword: string
  ): Promise<void>;
  rejectInvitation(
    email: string,
    code: string,
    client: string
  ): Promise<Response>;
  acceptInvitation(
    email: string,
    code: string,
    client: string
  ): Promise<Response>;
}

export default function create(settings: Settings): Login {
  return {
    isValidClientAndRedirectUrl,
    sendMFACode,
    getAuthorizationCode,
    sendPasswordResetCode,
    changePassword,
    setupPassword,
    createClient,
    register,
    acceptInvitation,
    rejectInvitation
  };

  async function isValidClientAndRedirectUrl(): Promise<boolean> {
    const response = await fetch(`${settings.apiUrl}/login/authorize`);
    return response.ok;
  }

  async function sendMFACode(
    username: string,
    password: string
  ): Promise<void> {
    const response = await fetch(
      `${settings.apiUrl}/login/authorizationToken`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password
        })
      }
    );

    if (!response.ok) {

      if (response.status === 401) {
        throw new IncorrectCredentialsError();
      }

      const responseBody = await response.json() as ResponseError;

      if (responseBody.errorType === 'disabled_account') {
        throw new DisabledAccountError();
      }

      throw buildResponseError(responseBody);
    }
  }

  async function getAuthorizationCode(
    username: string,
    password: string,
    code: string,
    client_id: string,
    redirect_uri: string
  ): Promise<CodeResponse> {
    const response = await fetch(`${settings.apiUrl}/login/authorizationCode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username,
        password,
        code,
        client_id,
        redirect_uri
      })
    });

    if (!response.ok) {
      const responseBody = await response.json() as ResponseError;

      if (responseBody.errorType === 'disabled_account') {
        throw new DisabledAccountError();
      }

      if (responseBody.errorType === 'invalid_or_expired_code') {
        throw new InvalidOrExpiredCodeError();
      }

      throw buildResponseError(responseBody);
    }

    return (await response.json()) as CodeResponse;
  }

  async function sendPasswordResetCode(username: string): Promise<void> {
    const response = await fetch(`${settings.apiUrl}/login/recoveryCode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username
      })
    });

    if (!response.ok) {
      throw buildResponseError(await response.json());
    }
  }

  async function setupPassword(
    email: string,
    code: string,
    password: string,
    confirmPassword: string
  ): Promise<void> {
    const response = await fetch(`${settings.apiUrl}/login/register`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: email,
        code,
        new_password: password,
        confirm_password: confirmPassword
      })
    });

    if (!response.ok) {
      throw buildResponseError(await response.json());
    }
  }

  async function changePassword(
    username: string,
    code: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<void> {
    const response = await fetch(`${settings.apiUrl}/login/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username,
        code,
        new_password: newPassword,
        confirm_password: confirmPassword
      })
    });

    if (!response.ok) {
      const responseBody = await response.json() as ResponseError;

      if (responseBody.errorType === 'invalid_or_expired_code') {
        throw new InvalidOrExpiredCodeError();
      }

      throw buildResponseError(responseBody);
    }
  }

  async function createClient(
    firstName: string,
    lastName: string,
    email: string,
    business: string,
    url: string,
    industry: string,
    visitors: string,
    recaptchaToken: string
  ): Promise<CreateClientResponse> {

    const getCookie = (name: string) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        const partsValue = parts.pop()?.split(';').shift();
        return partsValue;
      }
      return '';
    };

    const userAgentString = window.navigator.userAgent;


    const response = await fetch(`${settings.apiUrl}/clients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        business,
        url,
        industry,
        visitors,
        recaptchaToken,
        userAgent: userAgentString,
        fbp: getCookie('fbp'),
        fbc: getCookie('fbc')
      })
    });

    if (!response.ok) {
      throw buildResponseError(await response.json());
    }

    return (await response.json()) as CreateClientResponse;
  }

  async function register(
    firstName: string,
    lastName: string,
    email: string,
    code: string,
    password: string,
    confirmPassword: string
  ): Promise<void> {

    const response = await fetch(`${settings.apiUrl}/login/register`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstName,
        lastName,
        username: email,
        code,
        new_password: password,
        confirm_password: confirmPassword
      })
    });

    if (!response.ok) {
      throw buildResponseError(await response.json());
    }
  }

  async function rejectInvitation(
    email: string,
    code: string,
    client: string) {
    const response = await fetch(`${settings.apiUrl}/login/reject-invitation`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: email,
        code,
        client
      })
    });

    return response;
  }

  async function acceptInvitation(
    email: string,
    code: string,
    client: string) {
    const response = await fetch(`${settings.apiUrl}/login/accept-invitation`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: email,
        code,
        client
      })
    });


    return response;
  }
}

function buildResponseError(response: unknown): Error {
  const responseError = response as ResponseError;
  const message = `${responseError.message}: ${responseError.errors
    .map(e => `${e.msg}: ${e.value} for ${e.param}`)
    .join(', ')}`;
  throw new Error(message);
}

export type SignUpQuery = {
  state: string | null;
  client_id: string | null;
  redirect_uri: string | null;
  response_type: string | null;
};

const generateSignUpLink = (query: Partial<SignUpQuery>) => {
  const params = new URLSearchParams();

  (Object.keys(query) as Array<keyof SignUpQuery>).forEach(key => {
    if (query[key]) {
      params.append(key, query[key] as string);
    }
  });

  return `/sign-up?${params.toString()}`;
};

export default generateSignUpLink;

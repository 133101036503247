import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Heading, TextInput } from '../../shared';

import personIcon from '../../assets/icons/person.svg';
import passwordIcon from '../../assets/icons/password.svg';
import { Link } from '../../shared';
import { runEventHandler } from '../../utils';
import {
  DisabledAccountError,
  IncorrectCredentialsError,
  Login
} from '../../api/login';
import { UserFriendlyError } from '../../utils/runEventHandler';
import generateSignUpLink from '../../utils/generateSignUpLink';
import { SidebarLayout } from '../../shared/SidebarLayout/SidebarLayout';
import { widths } from '../../constants';

interface Props {
  clientDefinitions: Record<string, { redirect_uri: string }>;
  loginDefaultedRedirectUri: string;
  login: Login;
}

interface FormData {
  email: string;
  password: string;
}

export function OAuth(props: Props) {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);

  const state = params.get('state');
  const clientId = params.get('client_id') || '';
  const redirect_uri = params.get('redirect_uri');
  const response_type = params.get('response_type');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<FormData>();

  const isLoading = isSubmitting;

  const onSubmit = async (data: FormData) => {
    try {
      await props.login.sendMFACode(data.email, data.password);
    } catch (err) {
      reset(data);
      if (err instanceof IncorrectCredentialsError) {
        throw new UserFriendlyError(
          'Your email or password is incorrect. Please try again.'
        );
      } else if (err instanceof DisabledAccountError) {
        throw new UserFriendlyError('Your account has been disabled');
      }
      throw err;
    }

    navigate('/oauth/code', {
      state: {
        email: data.email,
        password: data.password,
        redirect_uri,
        client_id: clientId,
        state
      }
    });
  };

  if (
    props.clientDefinitions[clientId]?.redirect_uri !== redirect_uri ||
    response_type !== 'code'
  ) {
    window.location.assign(props.loginDefaultedRedirectUri);
  }

  return (
    <SidebarLayout
      sideBarPosition="left"
      tagline="Don't have an account?"
      link={{
        path: generateSignUpLink({
          state,
          client_id: clientId,
          redirect_uri,
          response_type
        }),
        name: 'Sign up'
      }}
    >
      <Form
        noValidate
        onSubmit={runEventHandler(handleSubmit(onSubmit))}
        isLoading={isLoading}
      >
        <Heading title="Login" />
        <TextInput
          icon={personIcon}
          errorMessage={errors?.email?.message}
          autoComplete="email"
          type="email"
          placeholder="Email"
          {...register('email', {
            required: {
              value: true,
              message: 'Please enter your email'
            },
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email'
            },
            validate: {
              lowercase: (v: string) =>
                !/[A-Z]/.test(v) ||
                'Your email must not contain an uppercase character'
            }
          })}
        />
        <TextInput
          icon={passwordIcon}
          errorMessage={errors?.password?.message}
          autoComplete="current-password"
          type="password"
          placeholder="Password"
          {...register('password', {
            required: {
              value: true,
              message: 'Please enter your new password'
            }
          })}
        />
        <Row>
          <Link to="/forgotten-password">Forgotten Password</Link>
        </Row>
        <Footer>
          <Button type="submit" isLoading={isLoading}>
            Continue
          </Button>
        </Footer>
      </Form>
    </SidebarLayout>
  );
}

interface FormProps {
  isLoading: boolean;
}

const Form = styled.form<FormProps>`
  flex: 1;
  padding-top: 25px;
  display: flex;
  flex-direction: column;

  ${({ isLoading }) => isLoading && 'cursor: progress;'}

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    padding-top: 0px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  margin-top: 55px;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    margin-top: 30px;
  }
`;

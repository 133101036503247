import React, { Ref, useId } from 'react';
import styled from 'styled-components';
import { colors, widths } from '../constants';

import { ErrorMessage } from './ErrorMessage';

interface Props extends React.ComponentPropsWithoutRef<'input'> {
  children?: React.ReactNode;
  errorMessage?: string;
}

function Checkbox(
  { children, errorMessage, className, ...props }: Props,
  ref: Ref<HTMLInputElement>
) {
  const errorId = useId();
  return (
    <Container className={className}>
      <Label>
        <Input ref={ref} type="checkbox" aria-describedby={errorId} {...props} />
        {children}
      </Label>
      <ErrorMessage id={errorId}>{errorMessage}</ErrorMessage>
    </Container>
  );
}

export default React.forwardRef(Checkbox);

const Container = styled.div``;

const Label = styled.label`
  color: ${colors.DARK_BLUE};
  font-style: italic;
  font-size: 18px;
  user-select: none;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 14px;
    line-height: 19px;
  }
`;

const Input = styled.input`
  margin: 4px;
  margin-right: 7px;
  width: 16px;
  height: 16px;
  accent-color: ${colors.DARK_BLUE};
`;

import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Heading, TextInput, Button, SidebarLayout } from '../../shared';
import { Login } from '../../api/login';

import emailIcon from '../../assets/icons/email.svg';
import passwordIcon from '../../assets/icons/password.svg';
import { useLocation } from 'react-router-dom';
import { runEventHandler } from '../../utils';

export interface FormData {
  email: string;
  password: string;
  confirmPassword: string;
  acceptTerms: string;
}

interface Props {
  login: Login;
  postRegistrationUrl: string;
}

export function SetupPassword(props: Props) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const email = params.get('email');
  const registrationCode = params.get('registration_code');

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = useForm<FormData>({ defaultValues: { email: email || '' } });

  if (!email || !registrationCode) {
    return <div>Access denied</div>;
  }

  const isLoading = isSubmitting || isSubmitSuccessful;

  const onSubmit = async (data: FormData) => {
    await props.login.setupPassword(
      email,
      registrationCode,
      data.password,
      data.confirmPassword
    );
    window.location.assign(props.postRegistrationUrl);
  };

  if (!email || !registrationCode) {
    return <div>Access Denied</div>;
  }

  return (
    <SidebarLayout sideBarPosition="right">
      <Form
        noValidate
        onSubmit={runEventHandler(handleSubmit(onSubmit))}
        isLoading={isLoading}
      >
        <Heading title="Set your password" subtitle="Create an account here" />
        <TextInput
          icon={emailIcon}
          errorMessage={errors?.email?.message}
          autoComplete="email"
          type="email"
          placeholder="Email"
          readOnly
          {...register('email', {
            required: {
              value: true,
              message: 'Please enter your email'
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email'
            }
          })}
        />
        <TextInput
          icon={passwordIcon}
          errorMessage={errors?.password?.message}
          autoComplete="new-password"
          type="password"
          placeholder="Password"
          {...register('password', {
            required: {
              value: true,
              message: 'Please enter your new password'
            },
            minLength: {
              value: 12,
              message: 'Password must be at least 12 characters long'
            },
            validate: {
              lowercase: (v: string) =>
                /[a-z]/.test(v) ||
                'Your password must contain a lowercase character',
              uppercase: (v: string) =>
                /[A-Z]/.test(v) ||
                'Your password must contain an uppercase character',
              numeric: (v: string) =>
                /[0-9]/.test(v) || 'Your password must contain a number',
              special: (v: string) =>
                /[^a-zA-Z0-9]/.test(v) ||
                'Your password must contain a special character'
            }
          })}
        />
        <TextInput
          icon={passwordIcon}
          errorMessage={errors?.confirmPassword?.message}
          autoComplete="new-password"
          type="password"
          placeholder="Confirm password"
          {...register('confirmPassword', {
            required: {
              value: true,
              message: 'Please confirm your new password'
            },
            validate: (value: string) =>
              value === watch('password') || "Your passwords don't match",
            deps: 'password'
          })}
        />

        <Footer>
          <Button type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </Footer>
      </Form>
    </SidebarLayout>
  );
}

interface FormProps {
  isLoading: boolean;
}

const Form = styled.form<FormProps>`
  flex: 1;

  display: flex;
  flex-direction: column;

  ${({ isLoading }) => isLoading && 'cursor: progress;'}
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

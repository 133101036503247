import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import background from './assets/background.png';
import { widths } from './constants';

export default function Layout() {
  return (
    <Page>
      <Blur />
      <Modal>
        <Outlet />
      </Modal>
    </Page>
  );
}

const Page = styled.div`
  background: url(${background}) no-repeat;
  background-size: cover;
  backdrop-filter: blur(5px);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const Blur = styled.div`
  background: rgba(6, 35, 61, 0.7);
  backdrop-filter: blur(8px);
  position: absolute;
  z-index: -1;
  height: 100vh;
	width: 100vw;
`;

const Modal = styled.div`
  background: white;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 5px 20px #06233D65;
  max-width: 782px;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    max-width: 335px;
    max-height: 100vh;
    overflow: auto;
  }
`;

import {  useEffect } from 'react';

interface TitleProps {
  title: string;
  children: JSX.Element
}

export function Title(props: TitleProps) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return props.children;
}

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import globeIcon from '../../assets/icons/globe.svg';

import createAssistantBackground from '../../assets/createAssistantBackground.svg';
import smallCreateAssistantBackground from '../../assets/smallCreateAssistantBackground.svg';

import { Button, Heading, TextInput } from '../../shared';
import { runEventHandler } from '../../utils';
import { colors, widths, SESSION_STORAGE_KEYS } from '../../constants';

interface SetupAssistantFormData {
  url: string;
}

const signUpClientSessionStorage = 'signUpClientKey';

export function SetupAssistant() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<SetupAssistantFormData>();

  useEffect(() => {
    const previousState = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.SIGNUP_SESSION
    );

    if (previousState) {
      reset(JSON.parse(previousState));
    }
  }, [reset]);

  // Custom loading to handle "create" and "skip" loading states.
  const [loading, setLoading] = useState<boolean>(false);
  const [actionSelected, setActionSeleted] = useState<'create' | 'skip' | null>(
    null
  );

  const onSubmit = async (data?: SetupAssistantFormData) => {
    try {
      setLoading(true);
      const storedClient = getStoredClient();
      const url = getUrl(data?.url);

      window.dataLayer.push({ event: 'sign-up-continue' });
      sessionStorage.setItem(
        getClientSessionKey(),
        JSON.stringify(storedClient)
      );

      navigate('/sign-up/setup-industry-visitors', {
        state: {
          ...state,
          url
        }
      });
    } catch {
      setLoading(false);
      setActionSeleted(null);
    }
  };

  if (!state) {
    return <Navigate to=".." replace={true} />;
  }

  const getUrl = (url: string | undefined) => {
    return url ? addProtocol(url).toLowerCase() : undefined;
  };

  const getClientSessionKey = () =>
    `${signUpClientSessionStorage}-${state.state}`;

  const getStoredClient = () => {
    const savedClient = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.SIGNUP_SESSION
    );

    if (savedClient) {
      return JSON.parse(savedClient);
    }
  };

  const addProtocol = (url: string): string => {
    const protocolPattern = `^https?:\\/\\/`;

    if (url.toLowerCase().match(protocolPattern)) {
      return url;
    }
    return `https://${url}`;
  };

  const validateUrl = (url: string) => {
    url = addProtocol(url);

    const errorMsg =
      'We are unable to process this URL. Please enter a different URL.';

    try {
      const urlObj = new URL(url);
      if (urlObj.protocol !== 'https:') {
        throw new Error('Invalid protocol');
      }
      if (!urlObj.hostname.includes('.')) {
        throw new Error('No TLD');
      }
      return true;
    } catch (e) {
      return errorMsg;
    }
  };

  return (
    <Form
      onSubmit={runEventHandler(handleSubmit(onSubmit))}
      isLoading={loading}
    >
      <Heading
        title="Our smart-tech automates everything for you"
        subtitle="Enter your homepage url to create your first enterprise-grade AI assistant"
      />
      <Label>Enter your homepage URL</Label>
      <TextInput
        errorMessage={errors?.url?.message}
        autoComplete="url"
        icon={globeIcon}
        placeholder="https://your homepage URL"
        {...register('url', {
          required: {
            value: true,
            message: 'Please enter your homepage URL'
          },
          validate: validateUrl
        })}
      />
      {/* Set disabled to prevent clicking on other actions when an action is already selected. */}
      <ButtonRow>
        <Button
          type="submit"
          isLoading={loading && actionSelected === 'create'}
          onClick={() => setActionSeleted('create')}
          disabled={!!actionSelected && loading}
        >
          Next
        </Button>
        <Button
          type="button"
          kind="text"
          onClick={runEventHandler(() => {
            setActionSeleted('skip');
            onSubmit();
          })}
          isLoading={loading && actionSelected === 'skip'}
          disabled={!!actionSelected && loading}
        >
          Skip
        </Button>
      </ButtonRow>
    </Form>
  );
}

interface FormProps {
  isLoading: boolean;
}

const Form = styled.form<FormProps>`
  height: 387px;
  background: url(${createAssistantBackground}) no-repeat;
  padding: 32px 50px;
  padding-bottom: 93px;

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: progress;
    `}

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    padding: 20px;
    padding-bottom: 35px;
    background: url(${smallCreateAssistantBackground}) no-repeat;
    background-position: center bottom -1px;
    height: unset;
  }
`;

const Label = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.GREEN};
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0px;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 12px;
    margin-bottom: 6px;
    line-height: 16px;
  }
`;

const ButtonRow = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  gap: 21px;
  margin-top: 24px;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    margin-top: 5px;
    margin-bottom: 80px;
  }
`;

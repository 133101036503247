export const colors = {
  DARK_BLUE: '#06233D',
  RED: '#EA555F',
  WHITE: '#ffffff',
  GREEN: '#009081',
  GREY: '#d5e7e5',
  GREY_TEXT: '#B1B1B1'
};

export const widths = {
  MAX_SMALL_SCREEN: 800
};

export const SESSION_STORAGE_KEYS = {
  SIGNUP_SESSION: 'signup-state'
};

import React from 'react';
import styled from 'styled-components';
import { colors, widths } from '../constants';

import greenCheck from '../assets/greencheck.svg';

interface RadioButtonProps
  extends React.ComponentPropsWithoutRef<'input'> {
  label: string;
}

function RadioButton(
  { label, ...inputProps }: RadioButtonProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <Label>
      <Input type="radio" ref={ref} {...inputProps} />
      <Img />
      {label}
    </Label>
  );
}

export default React.forwardRef(RadioButton);

const Label = styled.label`
  display: flex;
  align-items: center;
  text-align: center;
  flex: 50%;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }

  & > input:checked + img {
    background: url(${greenCheck});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px 20px;
    background-color: ${colors.GREEN}};
  }

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    flex: 100%;
  }
`;

const Input = styled.input`
  // visibility: hidden;
  display: none;
`;

const Img = styled.img`
  padding: 13px;
  background-color: transparent;
  border: 1px solid ${colors.GREEN};
  border-radius: 50px;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
`;

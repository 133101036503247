import React from 'react';
import styled, { css } from 'styled-components';
import { colors, widths } from '../constants';

interface Props {
  title: string;
  subtitle?: string;
}

export default function Heading(props: Props) {
  const subTitles = props.subtitle ? props.subtitle.split(',') : [];
  return (
    <Container>
      <H1>{props.title}</H1>
      {subTitles.length > 0 && (
        <P className="subTitle-wrapper">
          {subTitles.map((title, index) => (
            <SubTitleContainer key={`SubTitleContainer-${index}`}>
              <CheckboxContainer
                key={`CheckboxContainer-${index}`}
                isVisible={subTitles.length > 1}
                className="checkbox-wrapper"
              >
                <CheckMark />
              </CheckboxContainer>
              {title}
            </SubTitleContainer>
          ))}
        </P>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 18px;
`;

const H1 = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: ${colors.DARK_BLUE};
  margin: 0;
  margin-bottom: 10px;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 5px;
  }
`;

const P = styled.div`
  display: flex;
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  color: ${colors.DARK_BLUE};

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 12px;
    line-height: 14px;
    width: 100%;
  }
`;
const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    margin-right: 10px;
  }
`;
const CheckboxContainer = styled.div<{ isVisible: boolean }>`
  position: relative;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: ${colors.GREEN} 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-right: 5px;
  ${({ isVisible }) =>
    isVisible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    width: 18px;
    height: 18px;
  }
`;

const CheckMark = styled.div`
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 34%;
  top: 50%;
  height: 9px;
  width: 5px;
  border-bottom: 2px solid ${colors.WHITE};
  border-right: 2px solid ${colors.WHITE};

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    height: 7px;
    width: 4px;
    left: 34%;
    top: 48%;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Button, Heading, Link, SidebarLayout, TextInput } from '../../shared';

import emailIcon from '../../assets/icons/email.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { runEventHandler } from '../../utils';
import { Login } from '../../api/login';

interface FormData {
  email: string;
}

interface Props {
  login: Login;
}

export function ForgottenPassword(props: Props) {
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful, isValid }
  } = useForm<FormData>({
    mode: 'onChange', // validate on change for "Already have a code?" link to update immediately,
    defaultValues: {
      email: state?.email
    }
  });

  const isLoading = isSubmitSuccessful || isSubmitting;

  const onSubmit = async ({ email }: FormData) => {
    await props.login.sendPasswordResetCode(email);
    navigate('enter-recovery-code', { state: { email } });
  };

  return (
    <SidebarLayout sideBarPosition="right">
      <Form
        noValidate
        onSubmit={runEventHandler(handleSubmit(onSubmit))}
        isLoading={isLoading}
      >
        <Heading title="Forgotten password" />
        <TextInput
          icon={emailIcon}
          placeholder="Email"
          type="email"
          required
          errorMessage={errors?.email?.message}
          {...register('email', {
            required: {
              value: true,
              message: 'Please enter your email'
            },
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email'
            }
          })}
        />
        <Link
          disabled={!isValid}
          to="enter-recovery-code"
          state={{ email: watch('email') }}
        >
          Already have a code?
        </Link>
        <Footer>
          <Link to="/oauth">
            <Button kind="outlined">Back</Button>
          </Link>
          <Button type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </Footer>
      </Form>
    </SidebarLayout>
  );
}

interface FormProps {
  isLoading: boolean;
}

const Form = styled.form<FormProps>`
  flex: 1;

  display: flex;
  flex-direction: column;

  ${({ isLoading }) => isLoading && 'cursor: progress;'}
`;

const Footer = styled.div`
  margin-top: 55px;
  display: flex;
  align-items: flex-end;
  flex: 1;

  button {
    margin-right: 15px;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';

import { Button, Heading, SidebarLayout, Text, TextInput } from '../../shared';

import personIcon from '../../assets/icons/person.svg';
import passwordIcon from '../../assets/icons/password.svg';
import authenticationIcon from '../../assets/icons/authentication.svg';

import { runEventHandler } from '../../utils';
import {
  DisabledAccountError,
  InvalidOrExpiredCodeError,
  Login
} from '../../api/login';
import ResendButton from './ResendButton';
import { UserFriendlyError } from '../../utils/runEventHandler';
import generateSignUpLink from '../../utils/generateSignUpLink';

interface Props {
  login: Login;
}

interface FormData {
  email: string;
  password: string;
  code: string;
}

export function MFA(props: Props) {
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = useForm<FormData>({
    defaultValues: { email: state?.email, password: state?.password }
  });

  const isLoading = isSubmitSuccessful || isSubmitting;

  const onSubmit = async (data: FormData) => {
    try {
      const { code } = await props.login.getAuthorizationCode(
        data.email,
        data.password,
        data.code,
        state.client_id,
        state.redirect_uri
      );

      const query = new URLSearchParams({
        code,
        state: state?.state
      }).toString();

      window.location.assign(`${state.redirect_uri}?${query}`);
    } catch (err) {
      reset(data);
      if (err instanceof InvalidOrExpiredCodeError) {
        throw new UserFriendlyError(
          'The code you have entered is incorrect. Please request a new code.'
        );
      } else if (err instanceof DisabledAccountError) {
        throw new UserFriendlyError('Your account has been disabled');
      }
      throw err;
    }
  };

  if (
    !state?.email ||
    !state?.password ||
    !state?.state ||
    !state?.redirect_uri ||
    !state?.client_id
  ) {
    return <Navigate to=".." replace={true} />;
  }

  return (
    <SidebarLayout
      sideBarPosition="left"
      tagline="Don't have an account?"
      link={{
        path: generateSignUpLink(state),
        name: 'Sign up'
      }}
    >
      <FormArea isLoading={isLoading}>
        <Heading title="Login" />
        <Form
          id="mfa-form"
          noValidate
          onSubmit={runEventHandler(handleSubmit(onSubmit))}
        >
          <TextInput
            icon={personIcon}
            errorMessage={errors?.email?.message}
            autoComplete="email"
            type="email"
            placeholder="Email"
            readOnly
            {...register('email', {
              required: {
                value: true,
                message: 'Please enter your email'
              },
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email'
              }
            })}
          />
          <TextInput
            icon={passwordIcon}
            errorMessage={errors?.password?.message}
            autoComplete="current-password"
            type="password"
            placeholder="Password"
            readOnly
            {...register('password', {
              required: {
                value: true,
                message: 'Please enter your password'
              }
            })}
          />
          <TextInput
            icon={authenticationIcon}
            errorMessage={errors?.code?.message}
            autoComplete="off"
            type="text"
            placeholder="Authentication Code"
            {...register('code', {
              required: {
                value: true,
                message: 'Please enter your authentication code'
              },
              pattern: {
                value: /^[0-9a-zA-Z]{8}$/,
                message: 'Please enter a valid authentication code'
              }
            })}
          />
        </Form>
        <Text>
          An email has been sent to your account containing the authentication
          code.
        </Text>
        <Footer>
          <ResendButton
            email={state?.email}
            password={state?.password}
            login={props.login}
          />
          <Button form="mfa-form" type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </Footer>
      </FormArea>
    </SidebarLayout>
  );
}

interface FormProps {
  isLoading: boolean;
}

const FormArea = styled.div<FormProps>`
  flex: 1;
  padding-top: 25px;
  display: flex;
  flex-direction: column;

  ${({ isLoading }) => isLoading && 'cursor: progress;'}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  flex: 1;

  button {
    margin-right: 24px;
  }
`;

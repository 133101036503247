import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  Heading,
  TextInput,
  Button,
  Checkbox,
  SidebarLayout
} from '../../shared';
import { Login } from '../../api/login';

import personIcon from '../../assets/icons/person.svg';
import passwordIcon from '../../assets/icons/password.svg';
import { useLocation } from 'react-router-dom';
import { colors, widths } from '../../constants';
import { runEventHandler } from '../../utils';

export interface FormData {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  acceptTerms: string;
}

interface Props {
  login: Login;
  postRegistrationUrl: string;
}

export function Register(props: Props) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const email = params.get('email');
  const registrationCode = params.get('registration_code');

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful }
  } = useForm<FormData>();

  if (!email || !registrationCode) {
    return <div>Access denied</div>;
  }

  const isLoading = isSubmitting || isSubmitSuccessful;

  const onSubmit = async (data: FormData) => {
    await props.login.register(
      data.firstName,
      data.lastName,
      email,
      registrationCode,
      data.password,
      data.confirmPassword
    );
    window.location.assign(props.postRegistrationUrl);
  };

  if (!email || !registrationCode) {
    return <div>Access Denied</div>;
  }

  return (
    <SidebarLayout sideBarPosition="right">
      <Form
        noValidate
        onSubmit={runEventHandler(handleSubmit(onSubmit))}
        isLoading={isLoading}
      >
        <Heading title="Set your password" />
        <TextInput
          autoComplete="given-name"
          icon={personIcon}
          errorMessage={errors?.firstName?.message}
          placeholder="First name"
          {...register('firstName', {
            required: {
              value: true,
              message: 'Please enter your first name'
            }
          })}
        />
        <TextInput
          autoComplete="family-name"
          icon={personIcon}
          errorMessage={errors?.lastName?.message}
          placeholder="Last name"
          {...register('lastName', {
            required: {
              value: true,
              message: 'Please enter your last name'
            }
          })}
        />
        <TextInput
          icon={passwordIcon}
          errorMessage={errors?.password?.message}
          autoComplete="new-password"
          type="password"
          placeholder="Password"
          {...register('password', {
            required: {
              value: true,
              message: 'Please enter your new password'
            },
            minLength: {
              value: 12,
              message: 'Password must be at least 12 characters long'
            },
            validate: {
              lowercase: (v: string) =>
                /[a-z]/.test(v) ||
                'Your password must contain a lowercase character',
              uppercase: (v: string) =>
                /[A-Z]/.test(v) ||
                'Your password must contain an uppercase character',
              numeric: (v: string) =>
                /[0-9]/.test(v) || 'Your password must contain a number',
              special: (v: string) =>
                /[^a-zA-Z0-9]/.test(v) ||
                'Your password must contain a special character'
            }
          })}
        />
        <TextInput
          icon={passwordIcon}
          errorMessage={errors?.confirmPassword?.message}
          autoComplete="new-password"
          type="password"
          placeholder="Confirm password"
          {...register('confirmPassword', {
            required: {
              value: true,
              message: 'Please confirm your new password'
            },
            validate: (value: string) =>
              value === watch('password') || "Your passwords don't match",
            deps: 'password'
          })}
        />

        <StyledCheckbox
          errorMessage={errors?.acceptTerms?.message}
          {...register('acceptTerms', {
            required: {
              value: true,
              message:
                'Please accept the terms & conditions and privacy policy to continue'
            }
          })}
        >
          I accept the&nbsp;
          <ExternalLink
            href="https://ebi.ai/customer-terms-and-conditions/"
            target="_blank"
          >
            Terms & Conditions
          </ExternalLink>
          &nbsp;and&nbsp;
          <ExternalLink href="https://ebi.ai/privacy-policy/" target="_blank">
            Privacy Policy
          </ExternalLink>
        </StyledCheckbox>
        <Footer>
          <Button type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </Footer>
      </Form>
    </SidebarLayout>
  );
}

interface FormProps {
  isLoading: boolean;
}

const Form = styled.form<FormProps>`
  flex: 1;

  display: flex;
  flex-direction: column;

  ${({ isLoading }) => isLoading && 'cursor: progress;'}
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

const ExternalLink = styled.a`
  color: ${colors.GREEN};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;

  @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
    font-size: 13px;
    line-height: 18px;
  }

  :hover {
    text-decoration: underline;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  label {
    font-size: 16px;
    font-style: normal;

    @media (max-width: ${widths.MAX_SMALL_SCREEN}px) {
      font-size: 13px;
      line-height: 18px;
    }
  }

  input {
    accent-color: ${colors.GREEN};
  }
`;

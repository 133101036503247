import styled from 'styled-components';
import { colors } from '../constants';

const Text = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${colors.DARK_BLUE};
  margin-top: 0;
`;

export default Text;
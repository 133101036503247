import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Login } from '../../api/login';
import { UserFriendlyError } from '../../utils/runEventHandler';

interface Props {
  login: Login;
  postRegistrationUrl: string;
}

export function RejectInvitation(props: Props) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const email = params.get('email');
  const client = params.get('client');
  const registrationCode = params.get('registration_code');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (email && registrationCode && client) {
          const response = await props.login.rejectInvitation(
            email,
            registrationCode,
            client
          );

          if (response.ok) {
            window.location.assign(props.postRegistrationUrl);
          }
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          throw new UserFriendlyError(err.message);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!email || !registrationCode || !client) {
    return <div>Access Denied</div>;
  }
  return null;
}
